
.App {
  text-align: center;
}

/* For demo */
.ant-carousel .slick-slide {
  text-align: center;
  height: 160px;
  line-height: 160px;
  background: #364d79;
  overflow: hidden;
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.events {
  margin: 0;
  padding: 0;
  list-style: none;
}

.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.notes-month {
  font-size: 28px;
  text-align: center;
}

.notes-month section {
  font-size: 28px;
}

.ant-table {
  font-size: 13px;
}

.c-multi-drag-table.is-dragging table {
  /*border: 1px solid #4285f4;*/
}

.c-multi-drag-table .ant-col {
  position: relative;
}

.c-multi-drag-table .ant-col:first-child:after {
  display: block;
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 1px;
  height: 100%;
  /*background: #e2e2e2;*/
  z-index: 1;
}

.c-multi-drag-table .inner-col {
  position: relative;
  height: 100%;
  background: #fff;
  border: 1px solid #f0f0f0;
  padding: 10px;
  /* z-index: 2; */
}

.c-multi-drag-table .ant-table {
  border: 1px solid #f0f0f0;
}

.c-multi-drag-table .ant-table-thead > tr > th,
.c-multi-drag-table .ant-table-tbody > tr > td,
.c-multi-drag-table .ant-table tfoot > tr > th,
.c-multi-drag-table .ant-table tfoot > tr > td {
  padding: 2px 10px;
}

.c-multi-drag-table .ant-table-tbody > tr.ant-table-placeholder > td,
.c-multi-drag-table .ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: transparent;
}

.c-multi-drag-table .ant-table-tbody > tr.row-item {
  background: #fff;
}

.c-multi-drag-table
.ant-table-tbody.is-dragging-over
> tr.ant-table-placeholder {
  background: #deebff !important;
}

.c-multi-drag-table .ant-table-tbody > tr.row-item.row-exists td {
  background: #edffe5;
}

.c-multi-drag-table .ant-table-tbody > tr.row-item.row-dragging {
  display: table;
  color: #fff;
  background: #f5f8ff;
  border: 1px solid #f0f0f0;
}

.c-multi-drag-table .placeholder {
  width: 100%;
  outline: 1px dashed #bdbdbd;
  padding: 10px;
  color: #e6e6e6;
}

.c-multi-drag-table .ant-table-tbody > tr.row-item.row-dragging > td {
  border-bottom: 0;
}

.c-multi-drag-table .ant-table-tbody > tr.row-item.row-ghosting {
  opacity: 0.5;
}

.ant-list-items .ant-col:first-child::after {
  display: block;
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 1px;
  height: 100%;
  background: transparent;
  z-index: 1;
}

tr.table-row-error input.ant-input {
  border-color: red !important;
}

.ant-table table {
  font-size: 12px;
}

.ant-table-column-sorters {
  padding: 2px 1px;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
  padding: 1px 6px !important;
  vertical-align: baseline;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.title-label {
  min-height: 32px !important;
}

// убрал серую полоску справа от меню
.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  border-right: 0 !important;
}


.select-has-error .ant-select-selector {
  background-color: #fff;
  color: #ff4d4f !important;
  border-color: #ff4d4f !important;
}

.paag-bar {
  padding: 20px;
}

.paag-bar:nth-child(even) {
  background-color: #fffbee;
}

.paag-bar:nth-child(odd) {
  background-color: #f1fcff;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome, Edge, Opera and Firefox */
}

input.error {
  border-color: #ffbaba !important;
  border-width: 1px !important;
  background-color: rgba(255, 0, 0, 0.06);
}

div.cell.error {
  input {
    background-color: #ffbaba !important;
    border-width: 1px !important;
    background-color: rgba(255, 0, 0, 0.06);
  }
}

.del-btn {
  color: #ff8181;
  background-color: #fff7f7;
  border-color: #ffc5c5;

  &:hover {
    color: #FFF;
    background-color: #ff8c8c;
    border-color: #ff8c8c;
  }
}

.edit-btn {
  color: #1890FF;
  background-color: #ffffff;
  border-color: #54acff;

  &:hover {
    color: #FFF;
    background-color: #1890FF;
    border-color: #1890FF;
  }
}

.copy-btn {
  color: #62ad00;
  background-color: #ffffff;
  border-color: #92ea00;

  &:hover {
    color: #FFF;
    background-color: #77b200;
    border-color: #689f00;
  }
}

.gel {
  border: 1px solid #910097;
  display: inline-block;
  border-radius: 10px;
  min-width: 22px;
  text-align: center;
  background-color: #910097;
  color: #FFF;
  font-size: 12px;
  font-weight: bold;
  line-height: 20px;
  margin-left: 5px;
}

.ant-table-filter-trigger {
  margin: 0 !important;
}

.highlighted {
  background: #62ad00;
}

.selected {
  background: #62ad00;
}

.input-right {
  text-align: right;
}


// antd v5

.ant-page-header {
  font-feature-settings: "tnum", "tnum";
  box-sizing: border-box;
  color: rgba(0, 0, 0, .85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  margin: 0;
  padding: 16px 24px;
  position: relative;
}

.ant-page-header-heading {
  display: inline;
}

.ant-page-header-heading-title {
  color: rgba(0, 0, 0, .85);
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 0;
  margin-right: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  & div {
    display: inline;
  }
}

.ant-card-head {
  margin-top: 20px;
}

.page-header {
  height: 55px;
  padding: 10px 10px;
}

.ant-table-wrapper .ant-table.ant-table-small .ant-table-title, .ant-table-wrapper .ant-table.ant-table-small .ant-table-footer,
.ant-table-wrapper .ant-table.ant-table-small .ant-table-cell, .ant-table-wrapper .ant-table.ant-table-small .ant-table-thead > tr > th, .ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody > tr > th, .ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody > tr > td, .ant-table-wrapper .ant-table.ant-table-small tfoot > tr > th, .ant-table-wrapper .ant-table.ant-table-small tfoot > tr > td {
  padding: 0px;
}

.ant-table-cell {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.order-table .ant-table-cell {
  //padding: 0 !important;
  text-overflow: clip;
}

// это чтобы строка для редактирования была больше по высоте
.order-table .ant-table-tbody-virtual-holder > div {
  min-height: 400px;
}

.order-form .ant-form-item {
  margin-bottom: 5px;
}

// убираем отсекание текста в ячейках таблицы где есть ошибка
.ant-col.ant-form-item-control div:last-child {
  white-space: break-spaces !important;
}

.order-table .ant-table-cell {
  white-space: wrap !important;
}


.close-btn {
  border: 0;

  &:hover {
    color: rgba(0, 0, 0, 0.88) !important;
    background-color: rgba(0, 0, 0, 0.06) !important;
  }
}

.tabs-20 {
  .ant-tabs-nav-list {
    margin-left: 20px;
  }
}

.ant-layout {
  background: #fff;
}

.ant-btn-sm {
  font-size: 12px;
}

.selected-rows {
  overflow: hidden;
  position: absolute;
  left: 10px;
  top: 35px;
  z-index: 1000;
  width: 30px;
  text-align: center;
}

.custom-row {
  white-space: normal; /* Разрешить перенос строк */
  word-break: break-word; /* Разбивать длинные слова */
}

.bold-header {
  th {
    border-collapse: collapse !important;
    border: 1px solid #e0e0e0 !important;
    border-radius: 0 !important;
  }

  tr {
    border-collapse: collapse !important;
  }
}

.ant-collapse-header {
  padding: 0 !important;
  border-radius: 0 !important;
  //background-color: #FFF !important;
  //border-color: #FFF !important;
  //border: 0 !important;

}

.editable-row:hover {
  cursor: pointer;
  background: #fafafa;
}

.editable-row td {
  padding: 8px;
}

.editable-row {
  .ant-table-cell {
    padding: 10px 0 !important;
    background-color: #f0ad4e38;
  }
}

.order-row {
  .ant-table-cell {
    padding: 10px 0 !important;
  }
}

.section-header {
  margin-bottom: 10px;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 8px;
}

.portions-table {
  width: 100%;
  border-collapse: collapse;
}

.portions-table th,
.portions-table td {
  padding: 8px 16px;
  text-align: left;
  border-bottom: 1px solid #f0f0f0;
}

.portions-table th {
  background-color: #fafafa;
  font-weight: 500;
}
